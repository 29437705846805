import {
  PatronClaimComponent,
  VestingSchedule,
  PatronStatusStack,
} from '@infinex/ui/components';

import usePatronCountdown from 'hooks/usePatronCountdown';
import { formatNumber } from 'utils/utils';

import ClaimPatrons from './components/ClaimPatrons';

import usePatronData from '../../hooks/usePatronData';
import { EmptyState } from './components/EmptyState';
import PatronNftImage from 'components/PatronNftImage';
import {
  LINEAR_VESTING_END_DATE,
  LINEAR_VESTING_START_DATE,
  LOCKED_VESTING_END_DATE,
  LOCKED_VESTING_START_DATE,
} from '../../common';

export const HomePage = () => {
  const floorPrice = 3000;
  const countdown = usePatronCountdown();
  const patronData = usePatronData();

  // Return loading state if patron data is not loaded
  if (!patronData || !countdown) return null;

  const { dateOfNextEmission } = countdown;

  const {
    totalPatrons,
    claimablePatrons,
    lockedPatrons,
    claimedPatrons,
    vestedLinear,
    vestedLocked,
    linear,
    locked,
  } = patronData;

  // Vesting rows for Vesting Schedule Table
  const vestingItems = [
    {
      id: 1,
      period: 1,
      totalPatrons: linear.total,
      startDate: LINEAR_VESTING_START_DATE,
      endDate: LINEAR_VESTING_END_DATE,
      vested: vestedLinear,
      status: 'Vesting' as const,
    },
    {
      id: 2,
      period: 2,
      totalPatrons: locked.total,
      startDate: LOCKED_VESTING_START_DATE,
      endDate: LOCKED_VESTING_END_DATE,
      vested: vestedLocked,
      status: 'Locked' as const,
    },
  ];

  function RightChildren() {
    return (
      <div className="flex h-64 w-full max-w-80 flex-1 flex-col justify-between">
        <div className="flex flex-col">
          <div className="flex items-end justify-between">
            <p className="title-xs-medium text-slate11">Total Value</p>
            <p className="title-2xl-semibold text-slate12 leading-none">
              ${formatNumber(floorPrice * totalPatrons)}
            </p>
          </div>
          <p className="body-xs-normal text-subtle mt-1 self-end">
            Floor price: ${formatNumber(floorPrice)}
          </p>
        </div>
        <PatronStatusStack
          rows={[
            { type: 'claimed', amount: claimedPatrons },
            { type: 'available to claim', amount: claimablePatrons },
            { type: 'locked', amount: lockedPatrons },
          ]}
        />
      </div>
    );
  }

  return (
    <main className="relative w-full">
      <div className="absolute -right-48 top-24 hidden lg:block">
        <PatronNftImage />
      </div>
      <main className="container relative mx-auto flex max-w-screen-md flex-col gap-y-10 px-2 py-10 sm:max-w-[840px] lg:px-0">
        <div className="flex flex-col gap-y-1">
          <h1 className="title-2xl-semibold mb-5">My Patrons</h1>
          {totalPatrons === 0 ? (
            <EmptyState />
          ) : (
            <div className="flex flex-col gap-y-1">
              <PatronClaimComponent
                rightChildren={<RightChildren />}
                totalPatrons={totalPatrons}
                claimedPatrons={claimedPatrons}
                claimablePatrons={claimablePatrons}
                lockedPatrons={lockedPatrons}
              />
              <ClaimPatrons dateOfNextEmission={dateOfNextEmission} />
            </div>
          )}
        </div>
        <VestingSchedule vestingItems={vestingItems} />
      </main>
    </main>
  );
};
