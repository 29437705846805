import { createEvmSdk, type EvmSdkManifest } from '@infinex/evm-sdk';
import * as env from '@infinex/manifest';
const { test, prod, staging } = env;
import {
  ARBITRUM_RPC,
  BASE_RPC,
  ETHEREUM_RPC,
  OPTIMISM_RPC,
  POLYGON_RPC,
  BLAST_RPC,
  INFINEX_ENVIRONMENT,
} from '../config';

export const getEvmSdk = () => {
  const defaultManifest = (() => {
    const environment = INFINEX_ENVIRONMENT;
    return { dev: test, test, staging, prod }[environment];
  })();

  if (!defaultManifest) {
    throw new Error('Default manifest not found');
  }

  const evmSdkManifest: EvmSdkManifest = {
    ...defaultManifest,
    chains: {
      arbitrum: {
        ...defaultManifest.chains.arbitrum,
        chainConfig: {
          ...defaultManifest.chains.arbitrum.chainConfig,
          rpcUrls: {
            default: {
              http: [ARBITRUM_RPC || ''],
            },
          },
        },
      },
      base: {
        ...defaultManifest.chains.base,
        chainConfig: {
          ...defaultManifest.chains.base.chainConfig,
          rpcUrls: {
            default: {
              http: [BASE_RPC || ''],
            },
          },
        },
      },
      ethereum: {
        ...defaultManifest.chains.ethereum,
        chainConfig: {
          ...defaultManifest.chains.ethereum.chainConfig,
          rpcUrls: {
            default: {
              http: [ETHEREUM_RPC || ''],
            },
          },
        },
      },
      optimism: {
        ...defaultManifest.chains.optimism,
        chainConfig: {
          ...defaultManifest.chains.optimism.chainConfig,
          rpcUrls: {
            default: {
              http: [OPTIMISM_RPC || ''],
            },
          },
        },
      },
      polygon: {
        ...defaultManifest.chains.polygon,
        chainConfig: {
          ...defaultManifest.chains.polygon.chainConfig,
          rpcUrls: {
            default: {
              http: [POLYGON_RPC || ''],
            },
          },
        },
      },
      blast: {
        ...defaultManifest.chains.blast,
        chainConfig: {
          ...defaultManifest.chains.blast.chainConfig,
          rpcUrls: {
            default: {
              http: ['https://rpc.blastblockchain.com' || ''],
            },
          },
        },
      },
    },
  };

  const evmSdk = createEvmSdk(evmSdkManifest);

  return { evmSdk, evmSdkManifest };
};
