import { PublicKey } from '@solana/web3.js';
import { type Address } from 'viem';

// TODO: Remove 'balance' once STW removed and all assets are supported
export type AssetActionType = 'deposit' | 'withdraw' | 'bridge';

export type ChainAddress = PublicKey | Address | 'native';

export const ALL_ASSETS = [
  {
    id: 'eth',
    name: 'Ethereum',
    type: 'token',
    symbol: 'ETH',
    coingeckoId: 'ethereum',
  },
  {
    id: 'usd_coin',
    name: 'USDC',
    type: 'token',
    symbol: 'USDC',
    coingeckoId: 'usd-coin',
  },
  {
    id: 'usd_coin_ethereum_bridged',
    name: 'USDC (Bridged from Ethereum)',
    type: 'token',
    symbol: 'USDC.e',
    coingeckoId: 'usd-coin-ethereum-bridged',
  },
  {
    id: 'usd_ethena',
    name: 'Ethena',
    type: 'token',
    symbol: 'USDe',
    coingeckoId: 'ethena-usde',
  },
  {
    id: 'usd_tether',
    name: 'Tether',
    type: 'token',
    symbol: 'USDT',
    coingeckoId: 'tether',
  },
  {
    id: 'usd_blast',
    name: 'USD Blast',
    type: 'token',
    symbol: 'USDB',
    coingeckoId: 'usdb',
  },
  {
    id: 'usd_coin_base_bridged',
    name: 'USD Base Coin',
    type: 'token',
    symbol: 'USDbC',
    coingeckoId: 'bridged-usd-coin-base',
  },
  {
    id: 'sol',
    name: 'Solana',
    type: 'token',
    symbol: 'SOL',
    coingeckoId: 'solana',
  },
  {
    id: 'eth_lido_staked',
    name: 'Lido Staked Ether',
    type: 'token',
    symbol: 'stETH',
    coingeckoId: 'staked-ether',
  },
  {
    id: 'eth_lido_staked_wrapped',
    name: 'Wrapped stETH',
    type: 'token',
    symbol: 'WSTETH',
    coingeckoId: 'wrapped-steth',
  },
  {
    id: 'eth_wrapped',
    name: 'Wrapped ETH',
    type: 'token',
    symbol: 'WETH',
    coingeckoId: 'weth',
  },
  {
    id: 'sol_jito_staked',
    name: 'Jito Staked SOL',
    type: 'token',
    symbol: 'JITOSOL',
    coingeckoId: 'jito-staked-sol',
  },
  {
    id: 'eth_renzo_staked',
    name: 'Renzo Staked ETH',
    type: 'token',
    symbol: 'ezETH',
    coingeckoId: 'renzo-restaked-eth',
  },
  {
    id: 'op',
    name: 'Optimism',
    type: 'token',
    symbol: 'OP',
    coingeckoId: 'optimism',
  },
  {
    id: 'arb',
    name: 'Arbitrum',
    type: 'token',
    symbol: 'ARB',
    coingeckoId: 'arbitrum',
  },
  {
    id: 'dai',
    name: 'Dai Stablecoin',
    type: 'token',
    symbol: 'DAI',
    coingeckoId: 'dai',
  },
  {
    id: 'dai_savings',
    name: 'Savings Dai',
    type: 'token',
    symbol: 'SDAI',
    coingeckoId: 'savings-dai',
  },
  {
    id: 'cat_in_a_dogs_world',
    name: 'cat in a dogs world',
    type: 'token',
    symbol: 'MEW',
    coingeckoId: 'cat-in-a-dogs-world',
  },
  {
    id: 'dog_wif_hat',
    name: 'dogwifhat',
    type: 'token',
    symbol: 'WIF',
    coingeckoId: 'dogwifcoin',
  },
  {
    id: 'bonk',
    name: 'Bonk',
    type: 'token',
    symbol: 'BONK',
    coingeckoId: 'bonk',
  },
  {
    id: 'mog_coin',
    name: 'Mog Coin',
    type: 'token',
    symbol: 'MOG',
    coingeckoId: 'mog-coin',
  },
  {
    id: 'toshi',
    name: 'Toshi',
    type: 'token',
    symbol: 'TOSHI',
    coingeckoId: 'toshi',
  },
  {
    id: 'moutai',
    name: 'Moutai',
    type: 'token',
    symbol: 'MOUTAI',
    coingeckoId: 'moutai', //May be removed?
  },
  {
    id: 'mother_iggy',
    name: 'Mother Iggy',
    type: 'token',
    symbol: 'MOTHER',
    coingeckoId: 'mother-iggy',
  },
  {
    id: 'shiba_inu',
    name: 'Shiba Inu',
    type: 'token',
    symbol: 'SHIB',
    coingeckoId: 'shiba-inu',
  },
  {
    id: 'pepe',
    name: 'Pepe',
    type: 'token',
    symbol: 'PEPE',
    coingeckoId: 'pepe',
  },
  {
    id: 'brett', //Won't change for now, but if we add sol one should distinguish
    name: 'Brett',
    type: 'token',
    symbol: 'BRETT',
    coingeckoId: 'based-brett',
  },
  {
    id: 'mfer',
    name: 'mfercoin',
    type: 'token',
    symbol: 'MFER',
    coingeckoId: 'mfercoin',
  },
  {
    id: 'floki',
    name: 'FLOKI',
    type: 'token',
    symbol: 'FLOKI',
    coingeckoId: 'floki',
  },
  {
    id: 'popcat',
    name: 'Popcat',
    type: 'token',
    symbol: 'POPCAT',
    coingeckoId: 'popcat',
  },
  {
    id: 'degen',
    name: 'Degen',
    type: 'token',
    symbol: 'DEGEN',
    coingeckoId: 'degen-base',
  },
  {
    id: 'eth_dinero_staked',
    name: 'Pirex ETH',
    type: 'token',
    symbol: 'PXETH',
    coingeckoId: 'dinero-staked-eth',
  },
  {
    id: 'eth_dinero_staked_yield',
    name: 'Dinero apxETH',
    type: 'token',
    symbol: 'APXETH',
  },
  {
    id: 'sol_blazestake_staked',
    name: 'BlazeStake Staked SOL',
    type: 'token',
    symbol: 'BSOL',
    coingeckoId: 'blazestake-staked-sol',
  },
  {
    id: 'pyth_network',
    name: 'Pyth Network',
    type: 'token',
    symbol: 'PYTH',
    coingeckoId: 'pyth-network',
  },
  {
    id: 'uniswap',
    name: 'Uniswap',
    type: 'token',
    symbol: 'UNI',
    coingeckoId: 'uniswap',
  },
  {
    id: 'chainlink',
    name: 'Chainlink',
    type: 'token',
    symbol: 'LINK',
    coingeckoId: 'chainlink',
  },
  {
    id: 'aave',
    name: 'Aave',
    type: 'token',
    symbol: 'AAVE',
    coingeckoId: 'aave',
  },
  {
    id: 'synthetix',
    name: 'Synthetix Network',
    type: 'token',
    symbol: 'SNX',
    coingeckoId: 'havven',
  },
  {
    id: 'maker',
    name: 'Maker',
    type: 'token',
    symbol: 'MKR',
    coingeckoId: 'maker',
  },
  {
    id: 'velodrome',
    name: 'Velodrome Finance',
    type: 'token',
    symbol: 'VELO',
    coingeckoId: 'velodrome-finance',
  },
  {
    id: 'aerodrome',
    name: 'Aerodrome Finance',
    type: 'token',
    symbol: 'AERO',
    coingeckoId: 'aerodrome-finance',
  },
  {
    id: 'curve_dao',
    name: 'Curve DAO',
    type: 'token',
    symbol: 'CRV',
    coingeckoId: 'curve-dao-token',
  },
  {
    id: 'convex_finance',
    name: 'Convex Finance',
    type: 'token',
    symbol: 'CVX',
    coingeckoId: 'convex-finance',
  },
  {
    id: 'ondo_finance',
    name: 'Ondo',
    type: 'token',
    symbol: 'ONDO',
    coingeckoId: 'ondo-finance',
  },
  {
    id: 'pendle',
    name: 'Pendle',
    type: 'token',
    symbol: 'PENDLE',
    coingeckoId: 'pendle',
  },
  {
    id: 'ethena',
    name: 'Ethena',
    type: 'token',
    symbol: 'ENA',
    coingeckoId: 'ethena',
  },
  {
    id: 'wormhole',
    name: 'Wormhole',
    type: 'token',
    symbol: 'W',
    coingeckoId: 'wormhole',
  },
  {
    id: 'layerzero',
    name: 'LayerZero',
    type: 'token',
    symbol: 'ZRO',
    coingeckoId: 'layerzero',
  },
  {
    id: 'jupiter',
    name: 'Jupiter',
    type: 'token',
    symbol: 'JUP',
    coingeckoId: 'jupiter-exchange-solana',
  },
  {
    id: 'raydium',
    name: 'Raydium',
    type: 'token',
    symbol: 'RAY',
    coingeckoId: 'raydium',
  },
  {
    id: 'jito',
    name: 'Jito',
    type: 'token',
    symbol: 'JTO',
    coingeckoId: 'jito-governance-token',
  },
  {
    id: 'eth_rocket_pool',
    name: 'Rocket Pool ETH',
    type: 'token',
    symbol: 'RETH',
    coingeckoId: 'rocket-pool-eth',
  },
  {
    id: 'sol_jupiter_staked',
    name: 'Jupiter Staked SOL',
    type: 'token',
    symbol: 'JUPSOL',
    coingeckoId: 'jupiter-staked-sol',
  },
  {
    id: 'sol_socean_staked',
    name: 'Sanctum Infinity',
    type: 'token',
    symbol: 'INF',
    coingeckoId: 'socean-staked-sol',
  },
  {
    id: 'lil_pudgys',
    name: 'Lil Pudgys',
    type: 'nft',
    symbol: 'LilPudgys',
    coingeckoId: 'lilpudgys',
  },
  {
    id: 'sappy_seals',
    name: 'Sappy Seals',
    type: 'nft',
    symbol: 'SAPS',
    coingeckoId: 'sappy-seals',
  },
  {
    id: 'dinero',
    name: 'Dinero',
    type: 'token',
    symbol: 'DINERO',
    coingeckoId: 'dinero-2',
  },
  {
    id: 'solblaze',
    name: 'Blaze',
    type: 'token',
    symbol: 'BLZE',
    coingeckoId: 'solblaze',
  },
  {
    id: 'sol_helius_staked',
    name: 'Helius Staked SOL',
    type: 'token',
    symbol: 'HSOL',
    coingeckoId: 'helius-staked-sol',
  },
  {
    id: 'immutable',
    name: 'Immutable',
    type: 'token',
    symbol: 'IMX',
    coingeckoId: 'immutable-x',
  },
  {
    id: 'beam',
    name: 'Beam',
    type: 'token',
    symbol: 'BEAM',
    coingeckoId: 'beam-2',
  },
  {
    id: 'gala',
    name: 'GALA',
    type: 'token',
    symbol: 'GALA',
    coingeckoId: 'gala',
  },
  {
    id: 'axie_infinity',
    name: 'Axie Infinity',
    type: 'token',
    symbol: 'AXS',
    coingeckoId: 'axie-infinity',
  },
  {
    id: 'the_sandbox',
    name: 'The Sandbox',
    type: 'token',
    symbol: 'SAND',
    coingeckoId: 'the-sandbox',
  },
  {
    id: 'apecoin',
    name: 'ApeCoin',
    type: 'token',
    symbol: 'APE',
    coingeckoId: 'apecoin',
  },
  {
    id: 'stepn',
    name: 'GMT',
    type: 'token',
    symbol: 'GMT',
    coingeckoId: 'stepn',
  },
  {
    id: 'illuvium',
    name: 'Illuvium',
    type: 'token',
    symbol: 'ILV',
    coingeckoId: 'illuvium',
  },
  {
    id: 'gods_unchained',
    name: 'Gods Unchained',
    type: 'token',
    symbol: 'GODS',
    coingeckoId: 'gods-unchained',
  },
  {
    id: 'aavegotchi',
    name: 'Aavegotchi',
    type: 'token',
    symbol: 'GHST',
    coingeckoId: 'aavegotchi',
  },
  {
    id: 'guild_of_guardians',
    name: 'Guild of Guardians',
    type: 'token',
    symbol: 'GOG',
    coingeckoId: 'guild-of-guardians',
  },
  {
    id: 'echelon_prime',
    name: 'Echelon Prime',
    type: 'token',
    symbol: 'PRIME',
    coingeckoId: 'echelon-prime',
  },
  {
    id: 'renzo',
    name: 'Renzo',
    type: 'token',
    symbol: 'REZ',
    coingeckoId: 'renzo',
  },
  {
    id: 'infinex_governance_points',
    name: 'Infinex Governance Points',
    type: 'token',
    symbol: 'XGP',
  },
  {
    id: 'eigenlayer',
    name: 'Eigenlayer',
    type: 'token',
    symbol: 'EIGEN',
    coingeckoId: 'eigenlayer',
  },
  {
    id: 'patron_nft',
    name: 'Infinex Patrons',
    type: 'nft',
    symbol: 'XPATRON',
  },
  {
    id: 'spx_6900',
    name: 'SPX6900',
    type: 'token',
    symbol: 'SPX',
    coingeckoId: 'spx6900',
  },
  {
    id: 'harry_potter_obama_sonic_10_inu',
    name: 'Harry Potter Obama Sonic 10 Inu',
    type: 'token',
    symbol: 'BITCOIN',
    coingeckoId: 'harrypotterobamasonic10in',
  },
] as const;

export const ASSET_MAP: Map<AssetId, Asset> = new Map(
  ALL_ASSETS.map((a) => [a.id, a])
);

export const getAssetSymbol = (assetId: AssetId): AssetSymbol | 'UNKNOWN' => {
  if (!ASSET_MAP.has(assetId)) return 'UNKNOWN';
  return ASSET_MAP.get(assetId)!.symbol;
};

export const getAssetName = (assetId: AssetId): string | 'UNKNOWN' => {
  if (!ASSET_MAP.has(assetId)) return 'UNKNOWN';
  return ASSET_MAP.get(assetId)!.name;
};

// Define a helper type to extract the coingeckoId if it exists
type AssetWithCoingeckoId = (typeof ALL_ASSETS)[number] & {
  coingeckoId: string;
};

// Filter out the objects without coingeckoId
export type CoingeckoId = Exclude<
  AssetWithCoingeckoId['coingeckoId'],
  undefined
>;

export type AssetId = (typeof ALL_ASSETS)[number]['id'];
export type AssetSymbol = (typeof ALL_ASSETS)[number]['symbol'];
export type AssetType = (typeof ALL_ASSETS)[number]['type'];

export type Asset = {
  id: AssetId;
  name: string;
  type: AssetType;
  symbol: AssetSymbol;
  coingeckoId?: string;
};

export const ALL_ASSET_IDS = ALL_ASSETS.map((a) => a.id) satisfies AssetId[];
export const STABLE_ASSET_IDS: AssetId[] = [
  'usd_coin',
  'usd_tether',
  'usd_coin_base_bridged',
  'usd_coin_ethereum_bridged',
  'usd_ethena',
  'usd_blast',
  'dai',
];

// PATRON CONFIG
export const PATRON_PURCHASE_ALLOWED_ASSET_IDS: AssetId[] = [
  ...STABLE_ASSET_IDS.filter((asset) => asset !== 'usd_tether'),
  'op',
  'arb',
  'sol',
  'eth',
  'eth_wrapped',
  'infinex_governance_points',
];

export const ASSET_SPECIFIC_WITHDRAWAL_MINIMUMS: Partial<
  Record<AssetId, bigint>
> = {
  infinex_governance_points: 0n,
};
