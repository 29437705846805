import { forwardRef } from 'react';
import { Button as RACButton, Provider } from 'react-aria-components';

import { cn, isReactText } from '../../utils';
import { IconContext, Text, TextContext } from '../slots';
import { Spinner } from '../spinner';
import {
  buttonVariants,
  iconSizeClasses,
  spinnerSizeMap,
  textSizeClasses,
} from './buttonVariants';
import { ButtonProps } from './types';

/**
 * Buttons allow users to perform an action. They have multiple styles for
 * various needs, and are ideal for calling attention to where a user needs to
 * do something in order to move forward in a flow.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const { children, prominence, size = 'medium' } = props;

    const isPending = 'isPending' in props && props.isPending;
    const className = cn(buttonVariants({ prominence, size }), props.className);
    const textClassName = cn(textSizeClasses[size], { 'opacity-0': isPending });
    const iconClassName = cn(iconSizeClasses[size], { 'opacity-0': isPending });

    return (
      <Provider
        values={[
          [TextContext, { className: textClassName }],
          [IconContext, { className: iconClassName }],
        ]}
      >
        <RACButton
          {...props}
          className={className}
          ref={ref}
          // hack to prevent pending buttons from submitting their form. not my
          // favourite, but it's preferable to breaking expectations and/or
          // shifting the burden onto the consumers, forcing them to bail out of
          // the form submission
          form={
            props.isPending && props.type === 'submit'
              ? 'pending_no_form'
              : props.form
          }
        >
          {isReactText(children) ? <Text>{children}</Text> : children}
          {props.isPending && (
            <Spinner
              aria-label="Pending…"
              size={size ? spinnerSizeMap[size] : 'md'}
              className="absolute"
            />
          )}
        </RACButton>
      </Provider>
    );
  }
);
