import { LockClockIcon, SproutIcon } from '../../../icons';
import { formatNumber } from '../../../utils';
import { Divider } from '../../divider';
import { VestingItem } from '.';

export default function VestingScheduleMobile({
  vestingItems,
}: {
  vestingItems: VestingItem[];
}) {
  return (
    <div className="flex flex-col gap-y-10">
      {vestingItems.map((item) => (
        <div key={item.id} className="flex flex-col items-center">
          <div className="mb-6 flex flex-col items-center gap-y-4">
            <p className="title-2xs-medium">Period</p>
            <p className="title-4xl-semibold">{item.period}</p>
          </div>

          {item.totalPatrons - item.vested > 0 && (
            <div className="title-sm-medium border-shark4 mb-8 flex items-center gap-x-2 rounded-full border p-3">
              {item.status === 'Vesting' ? (
                <SproutIcon className="text-positiveSubtle size-4" />
              ) : item.status === 'Locked' ? (
                <LockClockIcon className="text-ruby11 size-4" />
              ) : null}
              {item.status}
            </div>
          )}

          <div className="w-full">
            {[
              {
                title: 'Start Date',
                value: item.startDate.toLocaleDateString('en-US', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                }),
              },
              {
                title: 'End Date',
                value: item.endDate.toLocaleDateString('en-US', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                }),
              },
              {
                title: 'Total Patrons',
                value: formatNumber(item.totalPatrons),
              },
              { title: 'Vested', value: formatNumber(item.vested) },
            ].map(({ title, value }, index, array) => {
              return (
                <div key={title}>
                  <div className="flex justify-between">
                    <p className="title-xs-medium text-subtle">{title}</p>
                    <p className="title-sm-medium text-slate11">{value}</p>
                  </div>
                  {index !== array.length - 1 && <Divider className="my-4" />}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
