import { forwardRef } from 'react';
import { Provider } from 'react-aria-components';

import { cn, isReactText } from '../../utils';
import { Link } from '../link';
import { IconContext, Text, TextContext } from '../slots';
import {
  buttonVariants,
  iconSizeClasses,
  textSizeClasses,
} from './buttonVariants';
import type { LinkButtonProps } from './types';

/**
 * Shares all the same `Button` cosmetics but with link semantics, allowing
 * users to navigate to another page.
 */
export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  function LinkButton(props, ref) {
    const {
      children,
      className,
      prominence,
      size = 'medium',
      ...otherProps
    } = props;

    return (
      <Provider
        values={[
          [IconContext, { className: iconSizeClasses[size] }],
          [TextContext, { className: textSizeClasses[size] }],
        ]}
      >
        <Link
          {...otherProps}
          className={cn(buttonVariants({ prominence, size }), className)}
          ref={ref}
        >
          {isReactText(children) ? <Text>{children}</Text> : children}
        </Link>
      </Provider>
    );
  }
);
