import {
  type EvmChainKey as SdkEvmChainKey,
  type EvmSdkManifest,
} from '@infinex/evm-sdk';
export type { SdkEvmChainKey };

import { Environment } from '.';

export const ALL_EVM_CHAIN_KEYS = [
  'arbitrum',
  'base',
  'ethereum',
  'optimism',
  'polygon',
  'blast',
] as const satisfies SdkEvmChainKey[];

export const SOLANA_CHAIN_KEY = 'solana' as const;
export const ALL_CHAIN_KEYS = [
  ...ALL_EVM_CHAIN_KEYS,
  SOLANA_CHAIN_KEY,
] as const;

export type EvmChainKey = (typeof ALL_EVM_CHAIN_KEYS)[number];
export type SolanaChainKey = typeof SOLANA_CHAIN_KEY;
export type ChainKey = EvmChainKey | SolanaChainKey;

const COINGECKO_MAINNET_PLATFORM_MAP = {
  arbitrum: 'arbitrum-one',
  base: 'base',
  ethereum: 'ethereum',
  optimism: 'optimistic-ethereum',
  polygon: 'polygon-pos',
  solana: 'solana',
  blast: 'blast',
} as const satisfies Record<ChainKey, string>;

export type CoingeckoPlatformId =
  (typeof COINGECKO_MAINNET_PLATFORM_MAP)[keyof typeof COINGECKO_MAINNET_PLATFORM_MAP];

export function getCoingeckoPlatformIdByChainKey(
  chain: ChainKey,
  env: Environment
): CoingeckoPlatformId | null {
  return env == 'prod' || env == 'staging'
    ? COINGECKO_MAINNET_PLATFORM_MAP[chain]
    : null;
}

export type AllContracts =
  EvmSdkManifest['chains'] extends Record<any, infer V>
    ? V extends { contracts: Record<infer K, unknown> }
      ? K
      : never
    : never;

export type ChainsWithContract<Contract extends AllContracts> = {
  [Chain in keyof EvmSdkManifest['chains']]: EvmSdkManifest['chains'][Chain]['contracts'] extends Record<
    Contract,
    any
  >
    ? Chain
    : never;
}[keyof EvmSdkManifest['chains']];

export type CctpEvmChainKey = ChainsWithContract<'MessageTransmitter'>;
export type CctpChainKey = 'solana' | CctpEvmChainKey;

export type CurveSwapChainKey = ChainsWithContract<
  'CurveStableSwapAppV1' | 'CurveStableSwapAppV2'
>;

/**
 * Returns EVM active / enabled chains for a given environment.
 **/
export function getActiveEvmChainKeys(env: Environment): EvmChainKey[] {
  return ALL_EVM_CHAIN_KEYS;
}

/**
 * Returns all active / enabled chains for a given environment.
 **/
export function getActiveChainKeys(env: Environment): ChainKey[] {
  return [...getActiveEvmChainKeys(env), SOLANA_CHAIN_KEY];
}
