import React, {
  type ForwardedRef,
  type HTMLAttributes,
  type ImgHTMLAttributes,
  type SVGAttributes,
  createContext,
  forwardRef,
} from 'react';
import {
  type HeadingProps,
  Header as RACHeader,
  Heading as RACHeading,
  Text as RACText,
  useContextProps,
} from 'react-aria-components';

/** Valid elements for components that allow `elementType` to be set. */
export type SlotElement =
  | 'aside'
  | 'div'
  | 'footer'
  | 'header'
  | 'section'
  | 'nav';

type SlottedValue<T> = {
  slots?: Record<string | symbol, T>;
};
export type SlottedContextValue<T> = SlottedValue<T> | T | null | undefined;
export type ContextValue<T, E extends Element> = SlottedContextValue<
  WithRef<T, E>
>;
export type WithRef<T, E> = T & { ref?: ForwardedRef<E> };

// Heading
// -----------------------------------------------------------------------------

export const HeadingContext = createContext<
  ContextValue<HeadingProps, HTMLHeadingElement>
>({});

/**
 * Represents a heading within a component-lib container. Provides no styling by
 * itself, but receives cosmetic and layout styles from the parent container.
 */
export const Heading = forwardRef(function Heading(
  props: HeadingProps,
  ref: ForwardedRef<HTMLHeadingElement>
) {
  [props, ref] = useContextProps(props, ref, HeadingContext);

  return <RACHeading {...props} ref={ref} />;
});

// Header
// -----------------------------------------------------------------------------

export const HeaderContext = createContext<
  ContextValue<HTMLAttributes<HTMLElement>, HTMLElement>
>({});

/**
 * Represents a header within a component-lib container. Provides no styling by
 * itself, but receives cosmetic and layout styles from the parent container.
 */
export const Header = forwardRef(function Header(
  props: HTMLAttributes<HTMLElement>,
  ref: ForwardedRef<HTMLElement>
) {
  [props, ref] = useContextProps(props, ref, HeaderContext);
  return <RACHeader {...props} ref={ref} />;
});

// Content
// -----------------------------------------------------------------------------

export const ContentContext = createContext<
  ContextValue<HTMLAttributes<HTMLElement>, HTMLDivElement>
>({});

/**
 * Represents the primary content area within a component-lib container.
 * Provides no styling by itself, but receives cosmetic and layout styles from
 * the parent container.
 */
export const Content = forwardRef(function Content(
  props: HTMLAttributes<HTMLElement> & { elementType?: SlotElement },
  ref: ForwardedRef<HTMLDivElement>
) {
  [props, ref] = useContextProps(props, ref, ContentContext);
  const { elementType: Element = 'div', ...otherProps } = props;
  return <Element {...otherProps} ref={ref} />;
});

// Icon
// -----------------------------------------------------------------------------

export const IconContext = createContext<
  ContextValue<SVGAttributes<SVGElement>, SVGSVGElement>
>({});

// NOTE: component functionality in `../../icons/createIcon.tsx` factory

// Text
// -----------------------------------------------------------------------------

export const TextContext = createContext<
  ContextValue<HTMLAttributes<HTMLElement>, HTMLDivElement>
>({});

/**
 * Represents text with no specific semantic meaning within a component-lib
 * container. Provides no styling by itself, but receives cosmetic and layout
 * styles from the parent container.
 */
export const Text = forwardRef(function Text(
  props: HTMLAttributes<HTMLElement>,
  ref: ForwardedRef<HTMLDivElement>
) {
  [props, ref] = useContextProps(props, ref, TextContext);
  return <RACText {...props} ref={ref} />;
});

// Footer
// -----------------------------------------------------------------------------

export const FooterContext = createContext<
  ContextValue<HTMLAttributes<HTMLElement>, HTMLDivElement>
>({});

/**
 * Represents a footer within a component-lib container. Provides no styling by
 * itself, but receives cosmetic and layout styles from the parent container.
 */
export const Footer = forwardRef(function Footer(
  props: HTMLAttributes<HTMLElement> & { elementType?: SlotElement },
  ref: ForwardedRef<HTMLDivElement>
) {
  [props, ref] = useContextProps(props, ref, FooterContext);
  const { elementType: Element = 'footer', ...otherProps } = props;
  return <Element {...otherProps} ref={ref} />;
});

// Image
// -----------------------------------------------------------------------------

export const ImageContext = createContext<
  ContextValue<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
>({});

/**
 * Represents an image within a component-lib container. Provides no styling by
 * itself, but receives cosmetic and layout styles from the parent container.
 */
export const Image = forwardRef(function Image(
  props: ImgHTMLAttributes<HTMLImageElement>,
  ref: ForwardedRef<HTMLImageElement>
) {
  [props, ref] = useContextProps(props, ref, ImageContext);

  if (props.alt == null) {
    console.warn(
      'The `alt` prop was not provided to an image. ' +
        'Add `alt` text for screen readers, or set `alt=""` prop to indicate that the image ' +
        'is decorative or redundant with displayed text and should not be announced by screen readers.'
    );
  }

  return <img alt={props.alt} {...props} ref={ref} />;
});
