import { useObjectRef } from '@react-aria/utils';
import { cva } from 'class-variance-authority';
import { type ReactNode, forwardRef } from 'react';
import { usePress } from 'react-aria';
import {
  type TextFieldProps as RACTextFieldProps,
  FieldError,
  Input,
  Label,
  TextField as RACTextField,
  Text,
} from 'react-aria-components';

import { type LiteralVariantProps, type StyleProps, cn } from '../../utils';

const inputVariants = cva(
  'w-full bg-none outline-none placeholder:text-subtle text-default caret-interactiveActive disabled:text-disabled',
  {
    variants: {
      size: {
        small: 'body-sm-normal h-8 px-3',
        medium: 'body-sm-normal h-10 px-3',
        large: 'body-base-normal h-12 px-4',
      },
    },
  }
);

type TextFieldVariant = LiteralVariantProps<typeof inputVariants>;

export type TextFieldProps = {
  /** The label of the field. For "visually hidden" labels, use the `aria-label` attribute. */
  label?: string;
  /** The label extension is displayed after the label, in a less prominent font. */
  labelExtension?: string;
  /**
   * Hint text is displayed below the label to give extra context or instruction
   * about what a user should input in the field.
   */
  hint?: string;
  /**
   * Avoid placeholders, which are inaccessible and disappear during input.
   * Prefer `hint` text to offer essential information.
   */
  placeholder?: string;
  /**
   * The size of the text field's input element.
   * @default medium
   */
  size?: TextFieldVariant['size'];
  /** Element to display before the input. For non-interactive elements use the `InputAdornment` component. */
  startElement?: ReactNode;
  /** Element to display after the input. For non-interactive elements use the `InputAdornment` component. */
  endElement?: ReactNode;
} & Omit<RACTextFieldProps, 'children' | 'className' | 'style'> &
  StyleProps;

/**
 * Text fields allow users to input text with a keyboard. Use when the expected
 * input is a single line of text.
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(props, forwardedRef) {
    const {
      className,
      endElement,
      hint,
      label,
      labelExtension,
      size = 'medium',
      startElement,
      ...otherProps
    } = props;

    const inputRef = useObjectRef(forwardedRef);

    // The "underlay" element sits behind everything, so this will only trigger
    // when the press is "through" (e.g. `pointer-events: none`) a start or end
    // element. Mimic browser behaviour:
    // - Press via mouse, focus immediately
    // - Press via touch, make sure it's not a scroll attempt
    const { pressProps } = usePress({
      isDisabled: props.isDisabled,
      onPressStart: (e) => {
        if (e.pointerType === 'mouse') {
          inputRef.current?.focus();
        }
      },
      onPressUp: (e) => {
        if (e.pointerType !== 'mouse') {
          inputRef.current?.focus();
        }
      },
    });

    // unlikely but better to warn
    if (labelExtension && !label) {
      console.warn('The `labelExtension` should accompany a `label`.');
    }

    return (
      <RACTextField
        className={cn(
          'flex w-full flex-col items-start gap-2',
          'group/field',
          className
        )}
        {...otherProps}
      >
        {label || labelExtension ? (
          <Label className="text-secondary body-sm-medium leading-none">
            {label}
            {labelExtension ? (
              <span className="font-light"> {labelExtension}</span>
            ) : null}
          </Label>
        ) : null}

        {hint ? (
          <Text slot="description" className="body-xs-normal text-subtle">
            {hint}
          </Text>
        ) : null}

        <div
          className={cn(
            'group/input',
            'group-disabled/field:cursor-default',
            'relative z-0 flex w-full cursor-text'
          )}
        >
          {startElement}

          <Input
            ref={inputRef}
            className={cn(
              inputVariants({ size }),
              'peer',
              // fix for mobile safari zoom behaviour:
              // - "touch" prefix targets `hover: none + pointer: coarse` by media query
              // - increase font to 16px (min allowed) regardless of field size
              'touch:body-base-normal',
              // needs review:
              // - i don't _think_ this is ever actually used in product…
              // - prefer "tabular-nums" rather than full monospace font?
              // - probably deserves a dedicated `NumberField` component, which
              //   would handle a bunch of other stuff around numeric input
              { 'font-mono': props.type === 'number' }
            )}
          />

          {/*
            Underlay element: indicate state and catch press events.
            Placement is important to allow first/last styles on adornment elements.
          */}
          <div
            aria-hidden
            className={cn(
              // fill available space and shift behind siblings
              'absolute inset-0 z-[-1]',
              // idle styles
              'bg-shark4 rounded border-none outline-none transition',
              // "group" styles apply relative to the input wrapper
              'group-hover/input:bg-shark5',
              // "peer" styles apply relative to the input element's state
              'peer-disabled:bg-shark3',
              'peer-focus:bg-shark4 peer-focus:ring-1 peer-focus:ring-inset peer-focus:ring-opacity-100'
            )}
            {...pressProps}
          />

          {endElement}
        </div>

        <FieldError className="text-critical body-xs-normal">
          {({ validationErrors }) => validationErrors.join(' ')}
        </FieldError>
      </RACTextField>
    );
  }
);
