import { cn, formatNumber } from '../../utils';
import { Divider } from '../divider';

type PatronStatusRow = {
  type: 'claimed' | 'available to claim' | 'unlocked' | 'locked';
  amount: number;
};

interface PatronStatusStackProps {
  rows: PatronStatusRow[];
}

export const PatronStatusStack = ({ rows }: PatronStatusStackProps) => {
  return (
    <div className="flex flex-col gap-y-6">
      {rows.map((row) => (
        <PatronStatusRow key={row.type} {...row} />
      ))}
    </div>
  );
};

const PatronStatusRow = ({ type, amount }: PatronStatusRow) => {
  const getBgColor = () => {
    if (amount === 0 && type !== 'locked') {
      return 'bg-transparent border border-shark5';
    }

    switch (type) {
      case 'claimed':
        return 'bg-accentDark';
      case 'available to claim':
      case 'unlocked':
        return 'bg-[#70FF93]';
      case 'locked':
        return 'bg-shark6';
      default:
        return '';
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-x-3">
        <div className={cn('h-2 w-4 rounded-full', getBgColor())} />
        <p className="title-xs-medium text-slate11">{type}</p>
      </div>
      <p
        className={cn(
          'title-2xl-semibold text-slate11',
          amount > 0 && type === 'available to claim' && 'text-slate12'
        )}
      >
        {formatNumber(amount)}
      </p>
    </div>
  );
};
